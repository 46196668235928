import $ from 'jquery'
import { handleAjaxCall } from './ajaxUtils'

$(function() {
  $('body').on('click', '[data-page-number]', function(e) {
    const ajaxUrl = $(e.target)
      .closest('.paginate')
      .data('ajax-url')
    if (!ajaxUrl) {
      return
    }
    const $targetEl = $(e.target).closest('[data-ajax-parent]')
    e.preventDefault()
    let href = new URL(ajaxUrl, window.location)
    const pageNumber = $(e.target)
      .closest('a')
      .data('page-number')
    const page = $(e.target)
      .closest('a')
      .data('page')
    href.searchParams.set(page, pageNumber)
    handleAjaxCall($targetEl, () => $.get(href))
  })
})
