import $ from 'jquery'

window.jQuery = $

require('autocomplete.js/dist/autocomplete.jquery')

$(function() {
  const queries = {}
  const searchUrl = $('.quick-search__field').attr('data-url')

  const executeQuery = queryBag => {
    $.getJSON(searchUrl, { q: queryBag.query }, data => {
      queryBag.results = data.results
      for (let name in queryBag.subscribers) {
        queryBag.subscribers[name](data.results[name].results)
        delete queryBag.subscribers[name]
      }
    })
  }

  const createSearch = name => {
    const search = (query, callback) => {
      if (queries.hasOwnProperty(query)) {
        const queryBag = queries[query]
        if (queryBag.results !== null) {
          callback(queryBag.results[name].results)
        } else {
          queryBag.subscribers[name] = callback
        }
      } else {
        queries[query] = {
          query,
          subscribers: { [name]: callback },
          results: null
        }
        executeQuery(queries[query])
      }
    }
    return search
  }

  const $quickSearch = $('.quick-search__field')

  const noResultsText = $quickSearch.attr('data-no-results-text')

  const header = (groupLabel) => {
    return `<div class="search-results__group-header">
          <div class="search-results__group-title">
            ${groupLabel}
          </div>
        </div>`
  }

  const dataset = (name, groupLabel) => {
    return {
      source: createSearch(name),
      displayKey: function(suggestion) {
        return suggestion.name
      },
      templates: {
        suggestion: function(suggestion) {
          return suggestion.html
        },
        header: ctx => {
          return ctx.isEmpty
            ? ''
            : header(groupLabel)
        },
        empty: function() {
          return header(`${groupLabel} - ${noResultsText}`)
        }
      }
    }
  }

  $('.quick-search__close').click(e => {
    e.preventDefault()
    $quickSearch.autocomplete('close')
  })
  $quickSearch
    .focus(e => { // eslint-disable-line
      $('.navbar--bsf').addClass('navbar-nav--search')
    })
    .blur(e => { // eslint-disable-line
      $('.navbar--bsf').removeClass('navbar-nav--search')
    })
  $quickSearch
    .autocomplete(
      {
        //debug: true,
        openOnFocus: false,
        keyboardShortcuts: ['s'],
        minLength: 1
        //dropdownMenuContainer: "#autocomplete-results",
      },
      [
        dataset('avmedia', $quickSearch.attr('data-avmedia-title')),
        dataset('person', $quickSearch.attr('data-person-title')),
        dataset('company', $quickSearch.attr('data-company-title'))
      ]
    )
    .on('autocomplete:selected', function(event, suggestion) {
      console.log(suggestion.url)
      window.location = suggestion.url
    })

  $quickSearch.on('autocomplete:opened', () => {
    $('body').append('<div id="quick-search-backdrop" class="quick-search-backdrop fade"></div>')
    window.requestAnimationFrame(() => $('#quick-search-backdrop').addClass('show'))
  })
  $quickSearch.on('autocomplete:closed', () => {
    $('#quick-search-backdrop').removeClass('show')
    window.requestAnimationFrame(() => $('#quick-search-backdrop').remove())
  })
})
