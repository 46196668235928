import $ from 'jquery'


function setupElement(el) {
  const $el = $(el)
  if (!window.loggedIn) {
    $el.loginModal($el)
    $el.removeAttr('data-fancybox')
    $el.removeAttr('data-gallery')
    return
  }
}

$.fn.prepareVideosForLogin = function() {
  return this.each(function() {
    setupElement(this)
  })
}
