import $ from 'jquery'

const SI_FIELDS = ['postal_service', 'address_proof']
const OTHER_FIELDS = ['zip_code', 'city', 'state']
const ADDRESS_FIELDS = ['address_line_1', 'address_line_2']

$(function() {
  const profileForm = $('#edit_profile')
  if (profileForm.length === 0) {
    return
  }

  const $country = $('#id_country')
  const domestic = $country.attr('data-domestic')

  function toggleFields(fields, value) {
    fields.forEach(field_name =>
      $(`#id_${field_name}`)
        .closest('.form-group')
        .toggle(value)
    )
  }

  function updateAddressFields() {
    const value = $country.val()
    if (value === '') {
      toggleFields([...ADDRESS_FIELDS, ...SI_FIELDS, ...OTHER_FIELDS], false)
    } else {
      toggleFields(ADDRESS_FIELDS, true)
      if (value === domestic) {
        toggleFields(SI_FIELDS, true)
        toggleFields(OTHER_FIELDS, false)
      } else {
        toggleFields(SI_FIELDS, false)
        toggleFields(OTHER_FIELDS, true)
      }
    }
  }

  $country.on('change', updateAddressFields)
  updateAddressFields()
})
