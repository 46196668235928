import $ from 'jquery'
import { INDEX_NAVIGATION_HEIGHT, NAVBAR_HEIGHT } from './constants'

function scrollAndOpenSection(targetId, sectionId) {
  const target = $(targetId).get(0)
  // NOTE: if headroom should stay pined
  //window.scroll(0, target.offsetTop - headerHeight())
  const selfAndParents = $(sectionId)
    .parents('.collapse')
    .addBack()
  // trigger async collapse function to update icons
  // immediately open self and parent sections, so scroll would work
  selfAndParents.addClass('show')
  selfAndParents.trigger('show.bs.collapse')
  // remove collapsed class in section head, so opened icons are shown
  selfAndParents.siblings('.section-head--collapsable').removeClass('collapsed')
  //const headerHeight = $('.index').outerHeight(true) || 0
  const headerHeight = INDEX_NAVIGATION_HEIGHT
  // substract -1px to underline proper section link
  const y = target.offsetTop - headerHeight - 1
  window.scroll(0, y > NAVBAR_HEIGHT ? y : 0)
}

$.fn.openSection = function() {
  return this.each(function() {
    const $src = $(this)
    $src.click(evt => {
      if ($src.is('[data-open-subsections]')) {
        $('.section-body', $src.attr('data-section')).collapse('show')
      }
      // manual scroll, to account for header height
      evt.preventDefault()
      window.requestAnimationFrame(() =>
        scrollAndOpenSection($src.attr('href'), $src.attr('data-section'))
      )
    })
  })
}
