import sl from './sl.json'
import en from './en.json'

const TRANSLATIONS = {
  en,
  sl,
}

export const t = (name) => {
  return TRANSLATIONS[window.language][name] || name
}
