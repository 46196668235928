import $ from 'jquery'

export const createModal = (title, content, opts = {}, footer) => {
  const options = {
    modalCssClass: 'modal fade',
    modalDialogCssClass: 'modal-dialog modal-dialog-centered',
    ...opts
  }
  const modalEl = $(`
<div class="${
  options.modalCssClass
}" tabindex="-1" role="dialog" aria-labelledby="loginModalTitle" aria-hidden="true">
  <div class="${options.modalDialogCssClass}" role="document">
    <div class="modal-content">
      <div id="modal-spinner" class="htmx-indicator modal-spinner tw-hidden [&.htmx-request]:tw-flex tw-w-full tw-h-full tw-absolute tw-top-0 tw-left-0 tw-items-center tw-bg-white-50 tw-z-10">
        <div class="tw-w-8 tw-text-adriatic-blue tw-mx-auto">
        <!-- include "partials/spinner.html" -->
        <svg class="animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        </div>
      </div>
      <div class="modal-header">
        <h5 class="modal-title" id="loginModalTitle">
          ${title}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" data-redirect-message="{% trans "redirecting..." %}">
          ${content}
      </div>
      <div class="modal-footer" id="modal-footer">
        ${footer || ''}
      </div>
  </div>
  </div>
</div>
`)
  $(document.body).append(modalEl)
  modalEl.modal()
  return modalEl
}
