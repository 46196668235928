import 'htmx.org'
import initWidgets from './initWidgets'

window.htmx = require('htmx.org')
//require('htmx.org/dist/ext/alpine-morph.js')
require('idiomorph/dist/idiomorph.cjs.js')

window.htmx.onLoad(function(el) {
  initWidgets(el)
})
