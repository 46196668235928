import $ from 'jquery'
import { submitAjaxForm, handleAjaxError } from './ajaxUtils'

let formLoaded = false

export function refreshCaptchaButton(target) {
  $('.js-captcha-refresh', target).click(function() {
    const $form = $(this).parents('form')

    $.getJSON('/captcha/refresh/', {}, function(json) {
      $('.captcha', $form).attr('src', json['image_url'])
      $('#id_captcha_0', $form).val(json['key'])
    })

    return false
  })
}


function submitForm(event, $form, $target) {
  const url = $form.attr('data-ajax-action') || $form.attr('action')
  const xhr = submitAjaxForm(url, event, $target)
  xhr.done(() => {
    refreshCaptchaButton($target)
  }).fail(handleAjaxError($target))
}

function getTarget($form) {
  const $target = $($form.attr('data-ajax-form-target'))
  if ($target.length !== 0) {
    return $target
  }
  return $form.closest('[data-ajax-parent]')
}

$(function() {
  const $section = $('#section-contact')
  const $target = $('#contact-form__placeholder', $section)
  const url = $target.attr('data-url')
  $section.on('show.bs.collapse', function() {
    if (!formLoaded) {
      $.get(url).then(data => {
        $target.html(data)
        formLoaded = true
      })
    }
  })

  $(document).on('submit', function(event) {
    const $form = $(event.target)
    if ($form.is('[data-no-ajax]')) {
      // use when non-ajax form is inside data-ajax-parent
      return
    }
    const $target = getTarget($form)
    if ($target.length) {
      submitForm(event, $form, $target)
    }
  })

  $(document).on('change', function(event) {
    const $form = $(event.target).closest('form')
    const $target = getTarget($form)
    if (!$form.is('[data-submit-form-on-change]')) {
      return
    }
    submitForm(event, $form, $target)
  })
})
