import htmx from 'htmx.org'
import $ from 'jquery'
import { t } from './i18n'

const loaderHtml = `
  <div class="loader">
    <div class="loader__spinner spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
`

export function handleAjaxError($targetEl) {
  return response => {
    $targetEl.html(`${t('error')}: ${response.statusText}`)
    toggleAjaxLoader($targetEl, false)
  }
}

export function toggleAjaxLoader(el, value = true) {
  const $el = $(el)
  let $loaderEl = $('.loader', $el)
  if ($loaderEl.length === 0) {
    $loaderEl = $(loaderHtml)
    $el.append($loaderEl)
  }

  // display spinner if request is not finished quickly
  $loaderEl.data('loading', true)
  window.setTimeout(() => {
    if ($loaderEl.data('loading')) {
      $loaderEl.toggleClass('loader--loading', value)
    }
  }, 200)
}

export function handleAjaxCall($targetEl, callback) {
  toggleAjaxLoader($targetEl)
  return callback()
    .done(data => {
      if ($targetEl) {
        $targetEl.html(data)
        htmx.process($targetEl[0])
        toggleAjaxLoader($targetEl, false)
      }
    })
    .fail(handleAjaxError($targetEl))
}

export function submitAjaxForm(url, event, $targetEl) {
  event.preventDefault()
  const $form = $(event.target).closest('form')
  $('input[type=submit]').attr('disable', true)
  const data = $form.serialize()
  const formMethod = ($form.attr('method') || 'POST').toUpperCase()
  const method = formMethod == 'POST' ? $.post : $.get
  const xhr = handleAjaxCall($targetEl, () =>
    method({
      url: url || $form.attr('action'),
      data: data,
      beforeSend: xhr => {
        xhr.setRequestHeader(
          'X-CSRFToken',
          $('[name=csrfmiddlewaretoken]').val()
        )
      }
    })
  )
  xhr.done(function() {
    if ($form.is('[data-ajax-replace-history]')) {
      history.replaceState(null, null, this.url)
    }
  })
  xhr.always(() => {
    $('input[type=submit]').attr('disable', false)
  })
  return xhr
}

export function loadPartial(url, target) {
  return fetch(url, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  })
    .then(response => response.text())
    .then(html => {
      target.innerHTML = html
    })
}

export function openLink(url, target, $event) {
  if ($event) {
    $event.preventDefault()
  }
  fetch(url, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.location) {
        window.location = response.location
      } else {
        target.innerHTML = response.html
      }
    })
}

export function submitForm(url, target, $event, follow) {
  $event.preventDefault()
  const data = new URLSearchParams(new FormData($event.target))
  const $targetEl = $(target)
  toggleAjaxLoader($targetEl)
  fetch(url, {
    method: 'post',
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    },
    body: data
  })
    .then(response => response.json())
    .then(response => {
      if (response.location) {
        if (follow) {
          openLink(response.location, target)
        } else {
          window.location = response.location
        }
      } else {
        toggleAjaxLoader($targetEl, false)
        target.innerHTML = response.html
      }
    })
}

export function isElementInViewport(el, tolerance = 150) {
  if (el.offsetParent === null) return false
  var rect = el.getBoundingClientRect()
  const top = rect.top
  const bottom = rect.bottom
  const viewportHeight =
    window.innerHeight || document.documentElement.clientHeight
  const viewportTop = 0 - tolerance
  const viewportBottom = viewportHeight + tolerance

  //console.log(viewportTop, viewportBottom, top, bottom)
  const result =
    (top >= viewportTop && top <= viewportBottom) ||
    (bottom >= viewportTop && bottom <= viewportBottom)
  return result
}

window.loadPartial = loadPartial
window.openLink = openLink
window.submitForm = submitForm
window.isElementInViewport = isElementInViewport
window.toggleAjaxLoader = toggleAjaxLoader
window.handleAjaxCall = handleAjaxCall
