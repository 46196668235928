import $ from 'jquery'

function setupElement(el) {
  const $src = $(el)
  const elementId = $src.attr('data-source-element-id')
  $src.click(() => {
    const $targetEl = $(`#${elementId}-collapse`)
    const sourceEl = document.getElementById(elementId)
    if ($targetEl.length > 0) {
      $targetEl.collapse('toggle')
      sourceEl.classList.toggle('open')
      el.classList.toggle('open')
    } else {
      $.get($src.attr('data-url')).then(data => {
        const $sourceEl = $(`#${elementId}`)
        $sourceEl.append(`
          <div id="${elementId}-collapse" class="collapse">
            ${data}
          </div>
        `)
        $(`#${elementId}-collapse`)
          .collapse()
          .collapse('show')
        sourceEl.classList.add('open')
        el.classList.add('open')
      })
    }
  })
}

$.fn.ajaxCollapseAfter = function() {
  return this.each(function() {
    setupElement(this)
  })
}
