import $ from 'jquery'

function setupElement(el) {
  const resetEl = $('<a href="" class="clearable-input__clear"></a>')
  $(el).after(resetEl)

  const toggleResetEl = term => {
    resetEl.toggle(term !== '')
    $(el).toggleClass('clearable-input--dirty', term !== '')
  }

  const onChange = () => {
    toggleResetEl($(el).val().toLowerCase())
  }

  $(el).keyup(onChange)
  $(el).change(onChange)

  resetEl.click(e => {
    e.preventDefault()
    $(el)
      .val('')
      .trigger('change')
  })

  onChange()
}

$.fn.clearableInput = function() {
  return this.each(function() {
    setupElement(this)
  })
}
