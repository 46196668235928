import $ from 'jquery'
import { handleAjaxCall } from './ajaxUtils'

$(function() {
  $('body').on('click', '[data-ajax-get]', function(e) {
    const ajaxUrl = $(e.target).data('ajaxGet')
    const $targetEl = $(e.target).closest('[data-ajax-parent]')
    e.preventDefault()
    handleAjaxCall($targetEl, () => $.get(ajaxUrl))
  })
})
