/**
 * Adds classes when sticky element is fixed.
 *
 */
export default function initSticky(stickyElement) {
  const sentinel = document.createElement('div')
  sentinel.style.position = 'absolute'
  sentinel.style.height = '1px'
  sentinel.style.width = '1px'

  stickyElement.parentElement.insertBefore(sentinel, stickyElement)

  const observer = new IntersectionObserver(
    ([e]) => {
      const classes = stickyElement.getAttribute('data-sticky').split(' ')
      if (e.intersectionRatio < 1) {
        stickyElement.classList.add(...classes)
      } else {
        stickyElement.classList.remove(...classes)
      }
    },
    { threshold: [0] }
  )

  observer.observe(sentinel)
}
