import $ from 'jquery'

function setupElement(el) {
  const wrapperEl = $(el)

  const placeholder = wrapperEl.attr('data-placeholder') || ''

  const searchInputEl = $(
    '<input class="form-control searchable-filter__search" type="text"/>'
  )
  searchInputEl.attr('placeholder', placeholder)
  const searchBarEl = $('<div class="searchable-filter__search-bar"/>')
  searchBarEl.append(searchInputEl)
  wrapperEl.before(searchBarEl)
  $(searchInputEl).clearableInput()

  const items = $('> div', wrapperEl)
  items.each((index, e) => {
    const value = $(e).text().trim().toLowerCase()
    $(e).attr(
      'data-text-search',
      value
    )
  })

  const filterItems = term => {
    if (term === '') {
      items.show()
    } else {
      items.hide()
        .filter('[data-text-search*="' + term + '"]')
        .show()
    }
  }

  const onChange = () => {
    const term = searchInputEl.val().toLowerCase()
    filterItems(term)
  }

  searchInputEl.keyup(onChange)
  searchInputEl.change(onChange)

}

$.fn.searchableFilter = function() {
  return this.each(function() {
    setupElement(this)
  })
}
