import Alpine from 'alpinejs'

import { isElementInViewport, loadPartial } from './ajaxUtils'
import { createVodCollectionCarousel2 } from './carousels2'
import timer from './timer'

/**
 * Lazily loads collection when it is in viewport.
 *
 * @param {} itemId - collection id
 * @param {} url - url for lazy loading
 */
const lazyCollection = (itemId, url) => ({
  loaded: false,
  itemId: itemId,

  init() {
    this.$nextTick(() => {
      if (isElementInViewport(this.$el)) {
        this.$dispatch('scrolled-into-view', { id: itemId })
      }
    })
  },

  loadInViewport: {
    ['@scrolled-into-view.window']() {
      if (!this.loaded && this.$event.detail.id === itemId) {
        this.loaded = true
        loadPartial(url, this.$el).then(() => {
          createVodCollectionCarousel2(this.$el)
        })
      }
    },
    ['@scroll.window']() {
      if (!this.loaded && isElementInViewport(this.$el)) {
        this.$dispatch('scrolled-into-view', { id: itemId })
      }
    },
    ['@collections-updated.window']() {
      if (!this.loaded && isElementInViewport(this.$el)) {
        this.$dispatch('scrolled-into-view', { id: itemId })
      }
    }
  }
})

Alpine.data('lazyCollection', lazyCollection)

// add/remove css class to element if children element is clamped
const addClampedClass = parentEl => {
  const el = parentEl.querySelector('.long-text-lc__text')
  parentEl.classList.toggle(
    'long-text-lc--clamped',
    el.scrollHeight > el.clientHeight
  )
}

//long-text-lc
Alpine.data('longTextLc', () => ({
  init() {
    const el = this.$root
    addClampedClass(el)
    const resizeObserver = new ResizeObserver(() => {
      addClampedClass(el)
    })
    resizeObserver.observe(el.querySelector('.long-text-lc__text'))
  },
  open() {
    this.$root.classList.add('long-text-lc--expanded')
    this.$root.classList.remove('long-text-lc--clamped')
  },
  close() {
    this.$root.classList.remove('long-text-lc--expanded')
    addClampedClass(this.$root)
  }
}))

function vodFilters() {
  return {
    checkedActions: [],
    showFilters: window.matchMedia('(min-width: 576px)').matches,
    isDirty: false,
    init() {
      this.updateIsDirty()
      this.$root.querySelectorAll('input[type=checkbox]').forEach(checkbox => {
        const label = checkbox.nextElementSibling.innerText
        if (checkbox.checked)
          this.checkedActions.push({
            name: checkbox.name,
            value: checkbox.value,
            label: label
          })
      })
    },
    handleChange(event) {
      const { name, value } = event.target
      const label = event.target.parentElement.innerText
      const action = { name, value, label }

      if (event.target.checked) {
        this.checkedActions.push(action)
      } else {
        this.checkedActions = this.checkedActions.filter(
          item => item.value !== value || item.name !== name
        )
      }
    },
    removeAction(name, value) {
      // Find and uncheck the checkbox, then update the list
      const checkbox = this.$root.querySelector(
        `input[type=checkbox][name='${name}'][value='${value}']`
      )
      if (checkbox) {
        checkbox.checked = false
        var event = new Event('change', {
          bubbles: true,
          cancelable: true
        })
        checkbox.dispatchEvent(event)
      }
    },
    countActionsByName(name) {
      return this.checkedActions.filter(action => action.name === name).length
    },

    isFormDirty() {
      const textInputs = this.$root.querySelectorAll('input[type="text"]')
      for (let input of textInputs) {
        if (input.value.trim() !== '') return true
      }

      const checkboxes = this.$root.querySelectorAll('input[type="checkbox"]')
      for (let checkbox of checkboxes) {
        if (checkbox.checked) return true
      }

      const selects = this.$root.querySelectorAll('select')
      for (let select of selects) {
        if (select.selectedIndex !== 0) return true
      }

      return false
    },

    updateIsDirty() {
      this.isDirty = this.isFormDirty()
    },

    resetForm() {
      this.$root
        .querySelectorAll('input[type="text"], input[type="checkbox"]')
        .forEach(function(input) {
          if (input.type === 'checkbox') {
            input.checked = false
          } else {
            input.value = ''
          }
        })
      this.$root.querySelectorAll('select').forEach(function(select) {
        select.selectedIndex = 0
      })
      var event = new Event('change', {
        bubbles: true,
        cancelable: true
      })
      this.checkedActions = []
      this.$root.dispatchEvent(event)
    }
  }
}

Alpine.data('vodFilters', vodFilters)

window.components = {
  timer
}

window.Alpine = Alpine
Alpine.start()
