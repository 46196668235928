import $ from 'jquery'

export default function welcomeText() {
  const welcomeEl = $('.frontpage__welcome')
  const btnEl = $('.frontpage__welcome-button')
  if (welcomeEl.length == 0) {
    return
  }
  const datePublished = welcomeEl.attr('data-published')
  const varName = `welcome${window.language}`
  const welcome = window.localStorage.getItem(varName)
  if (welcome !== datePublished) {
    btnEl.addClass('frontpage__welcome-button--new')
  }
  btnEl.click(() => {
    window.localStorage.setItem(varName, datePublished)
    btnEl.removeClass('frontpage__welcome-button--new')
  })
}
