import $ from 'jquery'

function setupElement(el) {
  const $openIcon = $('.long-text__open-icon', el)
  const $longText = $('.long-text__text', el)
  const $summary = $('.long-text__summary', el)
  const append = el.hasAttribute('data-append')
  $openIcon.click(() => {
    if (append) {
      $openIcon.hide()
    } else {
      $summary.hide()
    }
    $longText.show()
  })
  $('.long-text__close-icon', el).click(() => {
    $longText.hide()
    $openIcon.show()
    $summary.show()
  })
}

$.fn.longText = function() {
  return this.each(function() {
    setupElement(this)
  })
}
