function timer(expiry) {
  return {
    expiry: expiry,
    remaining: null,

    init() {
      this.setRemaining()
      setInterval(() => {
        this.setRemaining()
      }, 1000)
    },

    setRemaining() {
      const diff = this.expiry - new Date().getTime()
      this.remaining = parseInt(diff / 1000)
    },

    format(value) {
      return ('0' + parseInt(value)).slice(-2)
    },

    formatDays(days) {
      if (!days) {
        return ''
      }
      return (
        new Intl.NumberFormat(window.language || 'sl-SL', {
          unitDisplay: 'long',
          style: 'unit',
          unit: 'day'
        }).format(days) + ' '
      )
    },

    formatHoursAndMinutes(hours, minutes) {
      const hourslabel = Intl.NumberFormat(window.language || 'sl-SL', {
        unitDisplay: 'long',
        style: 'unit',
        unit: 'hour'
      }).formatToParts(hours)[2].value
      return `${hours}:${this.format(minutes)} ${hourslabel}`
    },

    formatRemainingTime() {
      let remainder = this.remaining
      if (remainder < 0) {
        return '0:00'
      }
      const days = Math.floor(remainder / 86400)
      remainder = remainder % 86400
      const hours = Math.floor(remainder / 3600)
      remainder = remainder % 3600
      const minutes = Math.floor(remainder / 60)
      /* eslint-disable-next-line no-unused-vars */
      const seconds = remainder % 60
      let result = `${this.formatDays(days)} ${this.formatHoursAndMinutes(
        hours,
        minutes
      )}`
      return result
    }
  }
}

export default timer
