import $ from 'jquery'
import { refreshCaptchaButton } from './ajaxForm'
import initSearchFilter from './searchFilter'

function loadDateRangeWidget() {
  return import(
    /* webpackChunkName: "dateRangeWidget" */ './daterangewidget'
  ).catch(err => console.log('error loading daterangewidget', err))
}

export default function initWidgets(target) {
  refreshCaptchaButton(target)

  if ($('input.daterange', target).length > 0) {
    loadDateRangeWidget().then(() => {
      $('input.daterange', target).dateRangeWidget()
    })
  }

  if ($('[data-image-crop]', target).length !== 0) {
    import(/* webpackChunkName: "cropperHelper" */ './cropperHelper')
      .then(module => {
        $('[data-image-crop]', target).each(module.default)
      })
      .catch(err => console.log('error loading cropperHelper', err))
  }

  $('[data-toggle="tooltip"]', target).tooltip()
  $('[data-toggle="ajax-collapse-after"]', target).ajaxCollapseAfter()
  $('[data-toggle="long-text"]', target).longText()
  $('[data-toggle="slider"]', target).slider()
  $('[data-toggle="open-modal"]', target).modal()

  if (target.dataset.toggle === 'open-modal') {
    $(target).modal()
  }

  $('.select2', target).select2()

  initSearchFilter()
}
