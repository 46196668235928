import $ from 'jquery'

export function trackEvent(category, action, name, value) {
  if (!window._paq) {
    return
  }
  window._paq.push(['trackEvent', category, action, name, value])
}

export function trackEventLightbox(el) {
  const actionName = el.attr('data-action-name') || 'lightbox'
  const href = el.attr('data-src') || el.attr('href')
  trackEvent('gallery', actionName, captionNameText(el), href)
}

export function titleFromCaptionHtml(html) {
  return $('<div/>')
    .html(html)
    .text()
    .split('|')[0]
}

export function captionNameText(el) {
  return titleFromCaptionHtml(el.attr('data-caption'))
}

export default function setupAnalyticsTags() {
  $('[data-track-event]').on('click', function() {
    const data = $(this).data()
    trackEvent(data.category, data.action, data.name, data.value)
  })
}

function trackEvents(events) {
  events.forEach(args => {
    trackEvent(...args)
  })
}

window.trackEvent = trackEvent
window.trackEvents = trackEvents
window.titleFromCaptionHtml = titleFromCaptionHtml
