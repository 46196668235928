import $ from 'jquery'

export default function ajaxLoad(el) {
  const $src = $(el)
  const jqxhr = $.get($src.attr('data-url'))
  jqxhr.then(data => {
    $src.html(data)
  })
  return jqxhr
}
