import $ from 'jquery'
import { trackEventLightbox } from './analytics'
import { createWatchlistHistory } from './profileUtils'

export function preparePdfLinksForFancybox() {
  if (window.isMobile || window.iOS) {
    return
  }
  const defaultAttributes = {
    'data-gallery-pdf': true,
    href: 'javascript:;'
  }
  const links = $('[data-pdf-eventually-fancybox]:not([data-gallery-pdf])')
  links.filter(':not([data-fancybox-index])').attr({...defaultAttributes, 'data-fancybox': 'pdf-gallery'})
  links.filter('[data-fancybox-index]').attr({...defaultAttributes, 'data-fancybox-trigger': 'pdf-gallery'})
}


export function galleryFancybox($el, opts=null) {
  $el.fancybox({
    protect: true,
    loop: true,
    thumbs: {
      autoStart: true
    },
    afterShow: function(instance, current) {
      const el = $(current.opts.$orig)
      trackEventLightbox(el)
      const video = el.attr('data-video')
      if (video) {
        createWatchlistHistory(video)
      }
    },
    mobile: {
      thumbs: {
        autoStart: false
      },
      preventCaptionOverlap: false,
      idleTime: false,
      beforeShow: function(instance) {
        const hideControlsInitially = false
        // initially hide controls on mobile
        if (hideControlsInitially && instance.firstRun) {
          instance.hideControls()
        }
      }
    },
    iframe: {
      preload: false,
      css: {
        width: '100%',
        height: 'auto',
        paddingTop: '56.25%'
      }
    },
    ...opts
  })
}

export default function initFancybox() {
  galleryFancybox($('[data-gallery]'))

  preparePdfLinksForFancybox()

  $('[data-fancybox][data-gallery-pdf]').fancybox({
    loop: true,
    thumbs: {
      autoStart: true
    },
    iframe: {
      preload: false,
      css: {
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        paddingTop: '0px'
      }
    },
    afterShow: function(instance, current) {
      const el = $(current.opts.$orig)
      trackEventLightbox(el)
    },
    buttons: [
      //'zoom',
      //"share",
      //'slideShow',
      //"fullScreen",
      //"download",
      'thumbs',
      'close'
    ]
  })
}

//fancybox needs data-fancybox-index for additional links
//setting it in html is not possible when fragment caching
//see. https://trello.com/c/04yGjWsR/1677-bug-s-klikom-na-pisno-gradivo-se-ne-odpre-pravo-pisno-gradivo-v-galeriji
$.fn.setDataFancyboxIndex = function() {
  return this.each(function(index) {
    this.dataset.fancyboxIndex = index
  })
}

window.galleryFancybox = galleryFancybox
