import debounce from 'lodash.debounce'
import { INDEX_NAVIGATION_HEIGHT } from './constants'

export default function initIndex() {
  if (!document.querySelector('.index')) {
    return
  }
  document.addEventListener(
    'scroll',
    debounce(() => {
      const sections = document.querySelectorAll('.index-section')
      const navLinks = document.querySelectorAll('.index-link')

      let index = 0

      while (
        index < sections.length &&
        window.scrollY + INDEX_NAVIGATION_HEIGHT >= sections[index].offsetTop
      ) {
        index += 1
      }

      navLinks.forEach(navLink => navLink.classList.remove('tw-underline'))
      navLinks[index === sections.length ? index - 1 : index].classList.add(
        'tw-underline'
      )
    }, 50)
  )
}
