import $ from 'jquery'

function displayBlockModal() {
  const lang = document.documentElement.getAttribute('lang')
  const jqxhr = $.get(`/${lang || 'sl'}/block-modal/`)
  jqxhr.then(data => {
    const $modal = $(data)
    $('body').append($modal)
    $modal.modal({
      keyboard: false,
      backdrop: 'static'
    })
  })
}

export default function blockModal() {
  if (document.documentElement.dataset.blockModal !== undefined) {
    displayBlockModal()
  }
}


