import $ from 'jquery'
import Cookies from 'js-cookie'

const userAgreementVersion = '1'

function acceptUserAgreement() {
  const value = userAgreementVersion + ':' + new Date().toISOString()
  Cookies.set('userAgreementVersion', value, {
    expires: 365 * 2
  })
}

function displayUserAgreement() {
  const jqxhr = $.get(`/${window.language || 'sl'}/user-agreement/`)
  jqxhr.then(data => {
    const $modal = $(data)
    $('body').append($modal)
    $modal.modal({
      keyboard: false,
      backdrop: 'static'
    })
    $('#acceptUserAgreement').click(() => {
      acceptUserAgreement()
      $modal.modal('hide')
    })
  })
}

function isGooglebot() {
  return /Googlebot|Google-InspectionTool/.test(navigator.userAgent)
}

export default function userAgreement() {
  if (document.documentElement.dataset.blockModal !== undefined) {
    return
  }
  if (window.location.host.indexOf('localhost') !== -1) {
    return
  }
  if ($('.festival-video-page').length > 0) {
    return
  }
  if (isGooglebot()) {
    return
  }
  const cookie = Cookies.get('userAgreementVersion')
  if (!cookie || cookie.split(':')[0] !== userAgreementVersion) {
    displayUserAgreement()
  }
}
