import $ from 'jquery'
import Swiper from 'swiper/bundle'


/**
 * breakpointsFor - creates swiper breakpoints so whole items are visible.
 *
 * @param {} itemWidth
 * @param {} gutter
 * @param {} num
 * @param {} padding
 */
const breakpointsFor = (itemWidth, gutter, num, padding = 0) => {
  const breakpoints = {}
  let width = 0
  while (width < 2000) {
    width = num * itemWidth + (num - 1) * gutter - padding
    breakpoints[width] = {
      slidesPerView: num,
      slidesPerGroup: num,
      spaceBetween: gutter
    }
    num += 1
  }
  return breakpoints
}

export const createVodCollectionCarousel2 = $el => {
  const swiperEl = $('.swiper-container', $el).get(0)

  const swiper = new Swiper(swiperEl, {
    mousewheel: { sensitivity: 1, forceToAxis: true },
    grabCursor: true,
    spaceBetween: 16,
    slidesPerView: '1',
    preloadImages: false,
    lazy: {
      loadPrevNext: true
    },
    watchSlidesProgress: true,
    breakpoints: breakpointsFor(310, 24, 2, -40),
    navigation: {
      nextEl: $('.tw-swiper-btn-next', $el).get(0),
      prevEl: $('.tw-swiper-btn-prev', $el).get(0)
    }
  })
  return swiper
}

window.createVodCollectionCarousel2 = createVodCollectionCarousel2
window.breakpointsFor = breakpointsFor
