import $ from 'jquery'

const updateParentCheckboxes = el => {
  for (var i = $(el).find('ul').length - 1; i >= 0; i--) {
    $(el)
      .find('ul:eq(' + i + ')')
      .prev('.custom-checkbox')
      .children('input:checkbox')
      .each((index, checkboxEl) => {
        $(checkboxEl).prop('checked', function() {
          // get children checkboxes
          const childrenCheckboxes = $(checkboxEl)
            .parent()
            .next('ul')
            .find('input:checkbox')

          const childrenCheckboxesAllDisabled =
            childrenCheckboxes.filter(':not(:disabled)').length === 0

          // parent is disabled if every children is disabled
          $(this).prop('disabled', childrenCheckboxesAllDisabled)
          $(this)
            .next('label')
            .toggleClass('label--disabled', childrenCheckboxesAllDisabled)

          // set checked/unchecked based on children state
          if (childrenCheckboxesAllDisabled) {
            // when all children checkboxes are disabled
            $(this).prop('checked', false)
          } else {
            // if any of checkboxes is unchecked, parent is unchecked
            $(this).prop(
              'checked',
              childrenCheckboxes.filter(':not(:checked):not(:disabled)')
                .length === 0
            )
          }
        })
      })
  }
}

const createNestedCheckboxes = element => {
  const nodes = $('<ul/>')
  const id = $(element)
    .parent()
    .attr('id')
  let currentParent = null
  let parentNode = null
  $('li, .nested-checkboxes-option', element).each((index, el) => {
    const parent = $('label', el).attr('data-parent')
    if (parent != currentParent) {
      parentNode = $('<ul/>')
      let li = $(`
        <li>
          <div class="custom-control custom-checkbox">
            <input name="__${id}-parent-${index}" id="${id}-parent-${index}" class="custom-control-input" type="checkbox" />
            <label for="${id}-parent-${index}" class="custom-control-label">${parent}</label>
          </div>
        </li>
        `)
      li.append(parentNode)
      nodes.append(li)
      currentParent = parent
    }
    parentNode.append(el)
  })

  $(element)
    .empty()
    .append(nodes)

  updateParentCheckboxes(nodes)

  $('input:checkbox', nodes).on('change', function() {
    const checked = $(this).prop('checked')
    const labelEl = $(this).parent()
    // update all children items
    $(labelEl)
      .next('ul')
      .find('input:checkbox:not(:disabled)')
      .prop('checked', checked)

    updateParentCheckboxes(nodes)
  })
}

$.fn.nestedCheckboxes = function(action) {
  if (action === 'refresh') {
    return this.each(function() {
      updateParentCheckboxes(this)
    })
  } else {
    return this.each(function() {
      createNestedCheckboxes(this)
    })
  }
}
