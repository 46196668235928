import $ from 'jquery'
import noUiSlider from 'nouislider'

function updateRange(event, sliderRange) {
  const sliderEl = $(this).get(0)
  const slider = sliderEl.noUiSlider
  const currentValues = slider.get()
  let [min, max] = sliderRange
  let start, stop
  // handle no need to change
  if (min === slider.options.range.min && max === slider.options.range.max) {
    return
  }
  // handle when current values are min max
  if (currentValues[0] == slider.options.range.min) {
    start = min
  } else {
    start = Math.max(min, currentValues[0])
  }
  if (currentValues[1] == slider.options.range.max) {
    stop = max
  } else {
    stop = Math.min(max, currentValues[1])
  }
  // handle when current values are out of range
  if (min === max) {
    max += 1
    start = min
    stop = max
    sliderEl.setAttribute('disabled', true)
  } else {
    sliderEl.removeAttribute('disabled')
  }
  $(sliderEl).data('skipTrigger', true)

  slider.updateOptions({
    start: [start, stop],
    range: {
      min,
      max
    }
  })
}

function setupElement(el) {
  const $sliderEl = $('<div class="slider__el" />')
  $sliderEl.prependTo(el)
  const data = $(el).data()
  const [min, max] = [parseInt(data.min), parseInt(data.max)]
  const field_min = $(`[name=${data.name}_min]`)
  const field_max = $(`[name=${data.name}_max]`)
  const start = [field_min.val() || min, field_max.val() || max]
  const step = data.step || 1
  const options = {
    start,
    step,
    connect: true,
    //tooltips: [true, true],
    format: {
      to: val => parseInt(val) + '',
      from: val => parseInt(val) + '',
    },
    range: {
      min,
      max
    }
  }
  $sliderEl.on('updateSliderRange', updateRange.bind($sliderEl))
  const sliderEl = $sliderEl.get(0)
  noUiSlider.create(sliderEl, options)
  field_min.hide()
  field_max.hide()
  sliderEl.noUiSlider.on('set', values => {
    const range = sliderEl.noUiSlider.options.range
    const start = values[0] == range.min ? '' : values[0]
    const stop = values[1] == range.max ? '' : values[1]
    const skipTrigger = $(sliderEl).data('skipTrigger')
    if (field_min.val() != start || field_max.val() != stop) {
      field_min.val(start)
      field_max.val(stop)
      if (!skipTrigger) {
        field_min.trigger('change')
      }
    }
    $(sliderEl).removeData('skipTrigger')
  })

  const setRangeValues = values => {
    $('.slider__range-values', el).text(
      `${values[0]} - ${values[1]}`
    )
  }
  sliderEl.noUiSlider.on('update', setRangeValues)
  setRangeValues(sliderEl.noUiSlider.get())
}

$.fn.slider = function() {
  return this.each(function() {
    setupElement(this)
  })
}
